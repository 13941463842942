@use 'sass:math';
@import '@hiredigital/ui/variables';

.header {
  text-align: center;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  max-width: 1200px;
  z-index: $z-base;
  min-height: 100vh;

  @include for-size(phone-only) {
    width: 100%;
    margin: auto;
  }
}

.formLogo {
  width: 180px;
  min-width: 180px;
  color: $black;
  margin: 12px 0;
}

.title {
  margin: 10px 0px 0px;
  margin-bottom: 5px;
  letter-spacing: -0.3px;
  color: $blue-120;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
}

.subtitle {
  letter-spacing: -0.3px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: $gray-80;
  text-align: center;
}

.smallNote {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  color: $gray-100;

  &.link {
    color: $primary-600;
    &:hover {
      text-decoration: underline;
    }
  }
}

.formContain {
  // padding: 100px 0px 100px 0px;
  // margin: 20px 0px 20px 0px;
  align-items: center;

  @include for-size(phone-only) {
    padding: 0;
  }
}

.bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
  @include for-size(phone-only) {
    display: none;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.title {
  margin: 10px 0px 0px;
  margin-bottom: 5px;
  letter-spacing: -0.3px;
  color: $blue-120;
  font-size: 20px;
  line-height: 1.5;
}

.subtitle {
  letter-spacing: -0.3px;
  margin-top: 10px;
  margin-bottom: 35px;
  color: $gray-80;
}

.form {
  background: $white;
  padding: 30px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  position: relative;
  max-width: 500px;
  min-height: 450px;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  will-change: height;
  width: 100%;

  &.scheduleACallForm {
    width: 750px;
  }

  @include for-size(phone-only) {
    box-sizing: border-box;
    max-width: 400px;
    box-shadow: none;
  }
}

.formButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;

  @include for-size(phone-only) {
    margin-top: 10px;
  }
}

.leadgenBottom {
  padding-top: 50px;

  @include for-size(phone-only) {
    display: none;
  }
}

.headline {
  color: $black;
  font-size: $size-6;
  font-weight: $fw-2;
  line-height: $line-height-6;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-5;
    line-height: $line-height-5;
  }
}

.label {
  color: $black;
}

.description {
  font-size: $size-4;
  line-height: $line-height-4;
  font-weight: $fw-1;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
  }
}

.logoContainer {
  width: 100%;
  display: flex;
}

.logo {
  margin: auto;
  height: 40px;
  width: 200px;
}

$animation-speed: 1000ms;
$dot-size: 5px;

.dot {
  width: $dot-size;
  height: $dot-size;
  border: 1px solid $white;
  border-radius: 50%;
  float: left;
  margin: 0 math.div($dot-size, 2);
  transform: scale(0);
  animation: fx 1000ms ease infinite 0ms;

  &:nth-child(2) {
    animation: fx 1000ms ease infinite 300ms;
  }

  &:nth-child(3) {
    animation: fx 1000ms ease infinite 600ms;
  }
}

@keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.enquiryMessageSuccess {
  color: $green-100;
  background-color: $green-10;
  padding: 12px;
  text-align: center;
}

.errorMsgLink {
  color: $blue-100;

  &:hover {
    color: $blue-120;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
}

.rateInputContainer {
  margin-bottom: 10px !important;
}

.inputAltAction {
  position: absolute;
  height: 21px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 50%;
  margin-top: -10.5px;
}

.enquiryMessageSuccess {
  color: $green-100;
  background-color: $green-10;
  padding: 12px;
  text-align: center;
}

.cLeadgenSuccess {
  display: flex;
  align-items: center;
  min-height: 32px;
  justify-content: center;
}

.resumeInputArea {
  position: relative;
  margin-bottom: 40px;
}

// New

.navContainer {
  margin-top: 15px;
  height: 40px;
}

.navDots {
  display: flex;
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.navDot {
  position: relative;
  padding: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: $border-radius;
    background-color: rgba(255, 255, 255, 0.4);
    border: 3px solid rgba(255, 255, 255, 0.4);
    transition: 0.1s all $cubic;
    position: absolute;

    // @include for-size(phone-only) {
    //   background-color: $white;
    //   border: 3px solid $blue-120;
    // }
  }

  &.active {
    &:after {
      background-color: $blue-100;
      border: 3px solid $white;

      // @include for-size(phone-only) {
      //   background-color: $blue-80;
      //   border: 3px solid $blue-120;
      // }
    }
  }

  &.hoverable {
    &:after {
      background-color: $white;
      border: 3px solid $white;

      // @include for-size(phone-only) {
      //   background-color: $white;
      //   border: 3px solid $blue-120;
      // }
    }

    &:hover {
      &:after {
        background-color: $blue-40;
        border: 3px solid $white;

        // @include for-size(phone-only) {
        //   background-color: $blue-80;
        //   border: 3px solid $blue-120;
        // }
      }
    }
  }
}

.loader {
  display: flex;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: $white;
  border-radius: 50%;
  float: left;
  margin: 0 math.div(5px, 2);
  transform: scale(0);
  animation: fx 1000ms ease infinite 0ms;

  &:nth-child(2) {
    animation: fx 1000ms ease infinite 300ms;
  }

  &:nth-child(3) {
    animation: fx 1000ms ease infinite 600ms;
  }
}

@keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.spaceRight {
  margin-right: 10px;
}

.inputToggle {
  // display: flex;
}

.inputToggleLabel {
  font-weight: 600;
  margin-bottom: 8px;
}

.inputToggleSubLabel {
}

.inputToggleButtonGroup {
  display: flex;
  width: 100%;
}

.inputToggleButton {
  padding: 0 15px;
  font-size: 14px;
  border: 1px solid $gray-10;
  z-index: $z-base;
  font-weight: 600;
  min-width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 24px;
  flex-grow: 1;

  &:first-of-type {
    border-top-left-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
  }

  &:last-of-type {
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }

  & ~ .inputToggleButton {
    margin-left: -1px;
  }

  &.active {
    z-index: $z-button;
    color: $blue-100;
    border-color: $blue-100;
  }
}

.clientDetailsSectionTitle {
  // color: $blue-120;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: $size-5;
}

.interestForm {
  margin-bottom: 30px;
}

.referenceInput {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.referenceArea {
  margin: 0;
}

.referenceAreaBrief {
  border: 1px solid $gray-20;
  border-top: 0;
  padding: 5px 3px 0 10px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.inputGroup {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;

  @include for-size(phone-only) {
    flex-direction: column;
  }

  .inputContainer {
    flex: 50%;
  }

  .inputContainer ~ .inputContainer {
    margin-left: 10px;

    @include for-size(phone-only) {
      margin-left: 0;
    }
  }
}

.modalContainer {
  // Used on engagements
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: default;
  // z-index: $z-dialog-mask;
  // pointer-events: none;
}

.mask {
  overflow: auto;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // z-index: $z-dialog-mask;
  background: rgba(0, 0, 0, 0.5);
}

.modalContent {
  padding: 30px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: $white;
  border-radius: 5px;
  z-index: $z-dialog;
  width: 450px;
  box-shadow: 0 13px 25px -7px rgba(49, 55, 60, 0.25);

  @include for-size(phone-only) {
    padding: 20px;
    min-height: 90%;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 90%;
    height: calc(90% - 50px);
  }
}

button.disabledLook {
  // Adding this for higher specificity
  background-color: $white;
  color: $blue-80;

  &:hover {
    background-color: $white;
    color: $blue-80;
    border: 1px solid $blue-80;
  }
}

.actionRow {
  display: flex;
  margin: auto;
}

.talentActionRow {
  display: flex;

  margin-top: auto;
}

.spaceBottom {
  margin-bottom: 10px;
}

.completedBrief {
  padding: 20px 0;
}

.note {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.info {
  border: 1px solid $blue-40;
  background-color: $blue-5;
}

.danger {
  border: 1px solid $red-15;
  background-color: $red-5;
  color: $red-15;
}

.completedItem {
  flex-basis: 50%;
  line-height: 22px;
  padding: 0 0 20px;
}

.completedLabel {
  font-size: 12px;
  font-weight: 600;
  color: $gray-40;

  color: $blue-120;
}

.completedAttachments {
  margin-top: 10px;
}

.completedAttachment {
  color: $blue-100;

  &:hover {
    color: $blue-120;
  }
}

.completedValue {
  font-size: 16px;
  color: $black;
}

.completedLink {
  font-size: 16px;
  color: $blue-100;

  &:hover {
    color: $blue-120;
  }
}

.completedRow {
  display: flex;
  flex-wrap: wrap;

  .completedItem {
    flex-basis: 50%;
    min-width: 200px;
  }
}

.section {
  display: flex;
  padding: 20px 0;
  padding-bottom: 10px;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
  }

  &.aboutSection {
    background-color: $blue-5;
  }
}

.sectionInfo {
  width: 300px;
  margin-right: 40px;
  flex-grow: 0;
  flex-shrink: 0;

  @include for-size(tablet-landscape-down) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.sectionForm {
  flex-grow: 1;
}

.infoTitle {
  font-size: $size-5;
  font-weight: 600;
  margin-bottom: 15px;

  @include for-size(tablet-landscape-down) {
    margin-bottom: 10px;
  }
}

.infoDesc {
  font-size: $size-3;
  line-height: $line-height-2;

  @include for-size(tablet-landscape-down) {
    font-size: $size-2;
  }
}

.callDesc {
  color: $gray-100;
  font-size: 16px;
  margin-bottom: 20px;
}

.resultTitle {
  margin-bottom: 10px;
  font-size: $size-6;
  line-height: $line-height-6;
}

.resultContent {
  font-weight: $fw-1;
  font-size: 16px;
  font-size: $size-3;
  line-height: $line-height-3;
}

.rateNotes,
.resumeNotes {
  color: $gray-60;
  font-weight: $fw-1;
  font-size: 15px;
}

.rateNotes {
  margin-bottom: 10px;
}

.resumeNotes {
  margin-top: 10px;
  visibility: hidden;

  &.resumeNotesVisible {
    visibility: visible;
  }
}

.corporateNote {
  label {
    color: $blue-120 !important;
  }
}

.offerForm {
  // Used in offer form
  width: 100%;
  max-width: 350px + 60px;
  // margin: 0px auto;
  margin-left: auto;
  box-sizing: border-box;
  border-radius: $border-radius;
  position: relative;
  box-shadow: $dark-shadow-30;

  display: flex;
  flex-direction: column;
  @include for-size(tablet-portrait-down) {
    margin: 20px auto 0;
    max-width: 100%;
  }
}

.offerFormInner {
  background: $white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px;
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.offerFormButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: $white;
  background: $blue-150;
  padding: 15px;
  font-size: $size-3;
  line-height: $line-height-3;
  height: 58px;
  &:hover {
    background: $blue-170;

    .offerArrow {
      transform: translateX(10px);
    }
  }

  &:active {
    background: $blue-170;
  }

  .offerArrow {
    margin-left: 5px;
    transition: transform 0.2s $cubic;
    font-size: $size-4;
  }

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.engagementDialog {
  max-width: 500px;
  min-height: 500px;
  width: 100%;
  padding: 30px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include for-size(tablet-portrait-down) {
    width: 90%;
    min-height: auto;
    height: calc(100% - 30px);
  }
}
